import 'twin.macro';

import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';

import PortableText from '../components/elements/PortableText';
import Layout from '../components/Layout';
import Seo from '../components/global/Seo';
import HomeHero from '../components/home/HomeHero';

const Article = ({ data }) => {
  const { article } = data;
  if (!article) return null;
  return (
    <Layout>
      <Seo
        title={article.title}
        description={article.shortDesc}
        image={
          article.featuredImage &&
          article.featuredImage.asset &&
          article.featuredImage.asset.url
        }
        keywords={article.seoKeywords}
      />
      <HomeHero
        title={article.title}
        fluid={
          article.featuredImage &&
          article.featuredImage.asset &&
          article.featuredImage.asset.gatsbyImageData
        }
        description={article.shortDesc}
      />
      {article._rawBody && <PortableText content={article._rawBody} />}
    </Layout>
  );
};

Article.propTypes = {
  data: PropTypes.object.isRequired,
};

export default Article;

export const query = graphql`
  query ($slug: String!) {
    article: sanityArticles(slug: { current: { eq: $slug } }) {
      _id
      title
      slug {
        current
      }
      shortDesc
      seoKeywords
      author {
        name
        twitter
        description
        avatar {
          asset {
            gatsbyImageData(formats: AUTO, width: 350, height: 350)
          }
        }
      }
      date(fromNow: true)
      _rawBody(resolveReferences: { maxDepth: 10 })
      featuredImage {
        alt
        asset {
          url
          gatsbyImageData(formats: AUTO, width: 1400)
          metadata {
            palette {
              dominant {
                background
                foreground
              }
              darkVibrant {
                background
              }
            }
          }
        }
      }
    }
  }
`;
